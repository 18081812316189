.filter {
  display: inline-block;
 }
 
 .filter__button {
  border-radius: 0.125rem;
  padding: 0.5rem 1rem;
  background-color: #00FFC6;
  background-image: none;
  cursor: pointer;
  border: transparent;
  font-weight: 600;
  color: white;
  font-size: 16px;
  line-height: 1.5;
 }
 
 .filter__button:hover {
  background-color: #00FFC6;
 }
 .filter__button:focus {
  outline: 1px dotted;
  outline: 1px auto -webkit-focus-ring-color;
 }
 
.filter__dropdown {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  /* padding: 0.25rem; */
  display: none;
  left: 0;
  max-height: calc(100vh - 152px);
  position: absolute;
  margin-top: 0.5rem;
  color: #00FFC6;
  background-color: #00061A;
 }
 @media (min-width: 768px) {
  .filter__dropdown {
    display: block;
    /* min-width: max-content; */
  }
 }
 .filter__dropdown__actions {
  border-top-width: 1px;
  border-top-color: #e4e7eb;
  border-top-style: solid;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem 0.5rem 0.25rem 0.5rem;
 }
 
 .filter__dropdown__actions button {
  border-radius: 0.125rem;
  padding: 0.25rem 0.5rem;
  background-color: #00FFC6;
  border-color: transparent;
  color: white;
  font-weight: 600;
  cursor: pointer;
 }

.technologies-listt {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 100%;
  padding: 3px;
}

.technologies-list {
  padding: 1px;
}

.technologies-list * {
  margin: 1px;
  padding: 1px;
}

.technologies-list > label {
  margin: 5px;
}