@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  src: url("/Fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "LibreBaskerville";
  src: url("/Fonts/Libre_Baskerville/LibreBaskerville-Regular.ttf");
}